import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import helpful_analytics_1 from "../../images/public-wifi/tab/helpful_analytics_1.svg";
import helpful_analytics_2 from "../../images/public-wifi/tab/helpful_analytics_2.svg";
import helpful_analytics_3 from "../../images/public-wifi/tab/helpful_analytics_3.svg";

export default function helpfulAnalyticsTab() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Understand your wireless environment"}
            </div>
            <div className="tab-desc">
              {"VostroNet's Q2 Platform provides detailed analytics valuable for both Network Management and User Insight."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5 align-items-center">
          <Col xs={12} md>
            <img src={helpful_analytics_1} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">
              {"Network-level analytics"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Q2 provides helpful network-level analytics such as throughput, number of devices, number of users and peak usage times."}
            </div>
          </Col>
        </Row>
        <Row className="my-5 align-items-center">
          <Col xs={12} md>
            <img src={helpful_analytics_2} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"User and device analytics"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Wi-Fi signal strength, authentication success, throughput, detailed logs on interaction with the network."}
            </div>
          </Col>
        </Row>

        <Row className="my-5 align-items-center">
          <Col xs={12} md>
            <img src={helpful_analytics_3} className="tab-img mr-3" alt="Integrated Wi-Fi Mesh" />
            <div className="tab-title">
              {"Location awareness"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Live geo-location map of users within the precinct. Live numerical data count of users in designated area."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}