import React from "react";
import { Container, Row, Col } from "react-bootstrap";


import secure_network_1 from "../../images/public-wifi/tab/secure_network_1.svg";
import secure_network_2 from "../../images/public-wifi/tab/secure_network_2.svg";

export default function SecureNetworkTab() {

  return (
    <div className="tab-connection">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Keeping users safe"}
            </div>
            <div className="tab-desc">
              {"VostroNet has architectually designed our network with enhanced cybersecurity features to keep users safe while online."}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-5 align-items-center">
          <Col xs={12} md>
            <img src={secure_network_1} className="tab-img mr-3" alt="high speed fibre" />
            <div className="tab-title">{"Security a priority"}</div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Enterprise-level encryption, DNS protection / malware prevention and PrivateMesh™ standard."}
            </div>
          </Col>
        </Row>
        <Row className="my-5 align-items-center">
          <Col xs={12} md>
            <img src={secure_network_2} className="tab-img mr-3" alt="experience difference" />
            <div className="tab-title">
              {"PrivateMesh™"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"PrivateMesh™ isolates groups from each other while facilitating sharing and collaborating where authorised."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}