import React from "react"
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";
import FeaturedCaseStudy from "../components/sections/case-study/featured-case-study";

import ourOwnFibreIcon from "../images/rne/info/ownfibre_icon.svg";
import connectedLivingIcon from "../images/rne/info/connected_living_icon.svg";
import qualityIcon from "../images/rne/info/quality_icon.svg";

import wineryIcon from "../images/mie/case-study/citywinery.svg";

import PlatformTab from "../components/tabs/platform";
import SecureNetworkTab from "../components/tabs/secure-network";
import HelpfulAnalyticsTab from "../components/tabs/helpful-analytics";

import studAccoIcon from "../images/other-mie/student_accom.png";
import buildToRentIcon from "../images/other-mie/buildtorent.png";
import hotelIcon from "../images/other-mie/hotels.png";
import coworkingIcon from "../images/other-mie/coworking.png";

import bannerImage from "../images/box-headers/public_wifi.jpg";
import bgImg from "../images/headers/subheader_blue.svg";

import {getCategoryId} from "../utils/categories";

const categoryId = getCategoryId("Public Wi-Fi");

const PublicWifi = () => (
  <Layout title="Public Wi-Fi">
    <SEO title="Public Wi-Fi - Internet and Wi-Fi Platform - VostroNet">
      <meta name="description" content="VostroNet are experts at deploying high speed public Wi-Fi internet. Our advanced Q2 software platform with analytics provides a secure and stable experience." />
    </SEO>
    <Banner subpage bgImg={bgImg} data={{
      title: {
        name: "Public Wi-Fi",
      },
      subtitles: [
        {
          name: `Online Everywhere`,
          className: "fw-400",
        },
        {
          name: `Stay connected wherever you go on VostroNet's seamless public Wi-Fi solution and analytics platform. Deploying best-in-class technology, we keep users online and safe`,
          className: "banner-text-small",
        },
      ],
      img: bannerImage,
    }}
      hideScrollButton
      customButtons={(<>
        <Container>
          <Row>
            <Col xs="auto">
              <Button variant="green" className="btn-banner mb-3" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-datasheet-public.pdf">
                <i className="fas fa-file-pdf mr-2" />
                {"Public Wi-Fi Datasheet"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-dark" className="btn-banner-secondary" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-solutions-public.pdf">
                <i className="fas fa-binoculars mr-2" />
                {"Quick overview"}
              </Button>
            </Col>
          </Row>
        </Container>
      </>)} />
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              {"Modernising Public Wireless"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"The Q2 Software Platform™ is a cloud-based Wi-Fi management system that centralises and automates the management of a public wireless environment. We provide users with a fast and secure connection through the Q2 Platform's captive portal with a simple authentication process."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <InformationSection data={{
      title: "First-class infrastructure",
      subtitle: "We are dedicated to delivering a reliable, fast, and expanding next-generation network underpinned by world-class technology. We own our own network infrastructure on quality equipment ensuring a great user experience.",
      subData: [
        {
          title: "Our own fibre",
          subtitle: "By owning and operating our own fibre from the data centre to the site, we ensure performance and quality is maintained at the highest level.",
          img: ourOwnFibreIcon,
        },
        {
          title: "Quality Design",
          subtitle: "We invest resources to tailor our design to suit the project. We use the best equipment to minimise downtime and maximise performance.",
          img: connectedLivingIcon,
        },
        {
          title: "Connected Living",
          subtitle: "Create a truly connected community with the assistance of quality Public Wi-Fi. We are experts in deploying and operating Wi-Fi.",
          img: qualityIcon,
        },
      ],
    }} />

    <Container className="py-5 tab-section">
      <Row>
        <Col>
          <Tabs defaultActiveKey="platform" id="public-wifi-tab-section">
            <Tab eventKey="platform" title="Platform">
              <PlatformTab />
            </Tab>
            <Tab eventKey="secure-network" title="Secure Network">
              <SecureNetworkTab />
            </Tab>
            <Tab eventKey="helpful-analytic" title="Helpful Analytics">
              <HelpfulAnalyticsTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
    <FeaturedCaseStudy data={{
      link: "https://cdn.vostro.cloud/content/casestudy-publicwifi-citywinery.pdf",
      title: "Featured case studies",
      desc: "Increasing customer engagement for City Winery Brisbane...",
      img: wineryIcon,
      sideNote: "VostroNet has deployed a high-speed public wireless solution to City Winery, enhancing the guest experience at the venue.",
    }} />
    <Blog category={categoryId} />
    <OtherMIESolutions data={[
      {
        label: "Student Accommodation",
        link: "/student-accommodation/",
        img: studAccoIcon,
      },
      {
        label: "Hotels",
        link: "/hotel/",
        img: hotelIcon,
      },
      {
        label: "Co-Working",
        link: "/co-working/",
        img: coworkingIcon,
      },
      {
        label: "Build-to-Rent",
        link: "/build-to-rent/",
        img: buildToRentIcon,
      },
    ]} />
  </Layout>
)

export default PublicWifi;
