import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import easyAdminIcon from "../../images/public-wifi/easy_admin_icon.svg";
import customisableIcon from "../../images/public-wifi/customisable_icon.svg";
import engagementIcon from "../../images/public-wifi/engagement_icon.svg";


export default function PlatformTab() {

  return (
    <div className="tab-platform">
      <Container fluid className="p-4">
        <Row>
          <Col>
            <div className="tab-main-title mb-3">
              {"Advanced Software"}
            </div>
            <div className="tab-desc">
              {"VostroNet offers a productive, professional software platform for public Wi-Fi environments; ensuring users can connect easily to the network"}
            </div>
          </Col>
        </Row>
        {/* desc */}
        <Row className="my-4 align-items-center">
          <Col>
            <img src={easyAdminIcon} alt="Easy administration" className="tab-img mr-3" />
            <div className="tab-title">
              {"Easy administration"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Administrators are provided with sophisticated tools to better manage and understand their user base."}
            </div>
          </Col>
        </Row>

        <Row className="my-4 align-items-center">
          <Col>
            <img src={customisableIcon} alt="Customisable" className="tab-img mr-3" />
            <div className="tab-title">
              {"Customisable"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Our platform is easily customisable to suit the branding of the hosting company. Customise the Wi-Fi experience via customisable landing pages and tailor content by profile, location, date and time."}
            </div>
          </Col>
        </Row>

        <Row className="my-4 align-items-center">
          <Col>
            <img src={engagementIcon} alt="Engagement" className="tab-img mr-3" />
            <div className="tab-title">
              {"Engagement"}
            </div>
          </Col>
          <Col xs={12} lg={6} xl={8}>
            <div className="tab-desc">
              {"Provide network users with important information, promotional material, or receive valuable feedback on user habits and time spent within the public space via VostroNet's advanced data analytics."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}